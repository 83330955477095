@import 'src/scss/functions';
@import 'src/scss/palette';

.home {
    .card-img-overlay {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .main-action-title {
        .card-img-overlay {
            margin: 0 !important;
            width: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $white;

            .main-action-button {
                margin-top: 60px;
                width: 300px;
                background-color: $blue;
                transition: all 200ms;

                &:hover {
                    background-color: #4d8cb5;
                }
            }
        }
    }

    &__container {
        margin: 0 auto;
        padding: 0 snif-grid(3);
        width: 100%;
        max-width: 1248px;

        @media #{$max-width-mobile} {
            position: relative;
            margin: snif-grid(-1.5) auto snif-grid(2);
            padding: 0;
        }
    }

    .browse-spots-link {
        color: $deep-gray;
        text-decoration: underline;
    }

    @media #{$max-width-mobile} {
        padding-top: 0;

        .home-review {
            width: 100%;
        }

        .big-picture {
            .card-img-overlay {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 16px;

                .main-action-button {
                    margin: 0 16px 8px;
                }
            }
        }

        .circle-overlay {
            position: absolute;
            top: -45px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            width: 650px;
            height: 334px;
            background-color: $white;
        }

        & > .home__container {
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: unset;
        }
    }

    @media #{$min-width-mobile} {
        .home__container {
            display: flex;
            justify-content: flex-end;
        }

        .host-landing__header {
            width: 100%;
            max-width: 450px;
            padding: snif-grid(4);
            background-color: $white;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
            border-radius: 16px;

            &.sign-up-test {
                overflow-y: auto;
            }

            &.logged-in {
                height: unset;
            }

            &.focused-with-spot {
                @media #{$min-width-mobile} {
                    position: absolute;
                }
            }
        }
    }

    .round-corners {
        border-radius: snif-grid(1);
    }

    .cursor-pointer {
        &:hover {
            cursor: pointer;
        }
    }

    .card {
        border-radius: snif-grid(1);

        &.no-border {
            border: none;
        }

        &.transparent {
            background: none;
        }

        @media #{$max-width-mobile} {
            border: none;
            overflow: hidden;
        }
    }

    .alert-success-color {
        color: $success-700;
    }

    .more-btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    .listing-btn {
        flex-shrink: 0;
        width: snif-grid(6);
        height: snif-grid(6);
        border-radius: 50%;
        background-color: $snow-white;
        margin: snif-grid(1) snif-grid(1) snif-grid(1) 0;

        &.sm {
            width: snif-grid(3);
            height: snif-grid(3);
            margin: snif-grid(1);
        }
    }

    .blog-more-pc,
    .explore-more-pc {
        height: 250px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
    }

    .reservation-block {
        cursor: pointer;

        .reservation-status {
            border: 1px solid $green;
            border-radius: snif-grid(1);
            padding: snif-grid(0.5) snif-grid(1);
            background-color: $white;
            position: absolute;
            top: snif-grid(3);
            left: snif-grid(1);

            @media #{$min-width-mobile} {
                position: relative;
                box-shadow: none;
                top: 0;
                left: 0;
            }
        }
    }

    .reservation-block {
        &.with-borders {
            border-radius: snif-grid(1);
            border: 1px solid $soft-gray;
        }

        .image {
            position: relative;
            padding-top: calc($photo-height / 2);
            width: 150px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: snif-grid(1);
            border-bottom-left-radius: snif-grid(1);
            overflow: hidden;

            &.variant {
                width: 100%;
                padding-top: $photo-height;
                border-radius: snif-grid(1);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .visit-again-block {
        @media #{$min-width-mobile} {
            width: calc(100% + 16px) !important;
            margin: 0 snif-grid(-1);

            & > div {
                width: 100%;

                & > div {
                    width: 100%;
                }
            }
        }
    }

    .other-spots-block {
        @media #{$min-width-mobile} {
            margin: 0 snif-grid(-1);
        }
    }

    &__star {
        width: snif-grid(4);
        height: snif-grid(4);
        margin-right: snif-grid(3);

        &__item {
            width: snif-grid(4);
            height: snif-grid(4);
            background-size: snif-grid(4);

            &.sniff-stars__item_empty {
                background-image: url('../../assets/svg/SvgStarGrayRegular.svg');
            }
        }
    }

    &__other-types {
        .sniff-other-types {
            padding: snif-grid(8) 0 snif-grid(3);
            background-color: $white;

            .l-container {
                max-width: 1300px;
                margin: 0 auto;
                padding: 0;
            }

            @media #{$max-width-mobile} {
                padding: snif-grid(4) 0;
                margin-bottom: snif-grid(-6);

                .l-container {
                    padding: 0 snif-grid(2);
                }
            }
        }
    }

    &__booked-other-types-pc {
        .sniff-other-types {
            padding: 0;
            background-color: $white;

            .l-container {
                max-width: 100%;
                padding: 0;
            }
        }
    }

    &__simple-other {
        position: relative;
        display: flex;
        align-items: center;
        padding: snif-grid(2) 0;
        border-bottom: 1px solid $fog-gray;

        svg {
            &:nth-child(1) {
                width: 20px;
                height: 20px;
                margin-right: snif-grid(1.5);
                color: $dark-gray !important;
            }

            &:nth-last-child(1) {
                position: absolute;
                top: 50%;
                right: 16px;
                width: 12px;
                height: 12px;
                transform: translateY(-50%);
                color: $light-gray !important;
            }
        }

        p {
            width: calc(100% - 32px);
            color: $dark-gray !important;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-decoration: none !important;
        }
    }

    &__cta {
        padding: snif-grid(8) 0 snif-grid(2);
        text-align: center;

        @media #{$max-width-mobile} {
            padding: snif-grid(6) 0 snif-grid(2);
        }
    }

    &__sub-types {
        .sub-types-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: snif-grid(3) 0;
            border-bottom: 1px solid $fog-gray;

            &:nth-child(1) {
                padding-top: 0;

                .types-item-left {
                    > svg {
                        color: $success-500;
                    }
                }
            }

            &:nth-child(2) {
                .types-item-left {
                    > svg {
                        color: $blue;
                    }
                }
            }

            &:nth-last-child(1) {
                padding-bottom: 0;
                border-bottom: none;

                .types-item-left {
                    > svg {
                        color: $medium-gray;
                    }

                    .types-item-left-right .snif-m2 {
                        @media #{$max-width-mobile} {
                            font-size: 16px !important;
                            line-height: 24px !important;
                        }
                    }
                }
            }

            .types-item-left {
                display: flex;
                width: 82%;

                > svg {
                    width: 24px;
                    height: 24px;
                }

                .types-item-left-right {
                    width: calc(100% - 40px);
                    margin: 0 snif-grid(2);
                }
            }

            .types-icon-right {
                height: 12px;
                color: $medium-gray;
                margin-right: snif-grid(2);
            }
        }

        @media #{$min-width-mobile} {
            display: flex;
            justify-content: space-between;
            margin-top: 0;
            position: relative;

            .sub-types-item {
                display: block;
                padding: snif-grid(4) !important;
                width: calc(100% / 3 - 16px);
                min-height: 160px;
                text-align: center;
                border: 1px solid $fog-gray !important;
                border-radius: 8px;
                background-color: $white;
                cursor: pointer;

                .types-item-left {
                    flex-direction: column;
                    align-items: center;
                    width: 100% !important;

                    > svg {
                        width: 32px;
                        height: 32px;
                    }
                }

                .types-item-left-right {
                    margin: snif-grid(3) 0 0 0 !important;

                    .snif-s1 {
                        display: none;
                    }
                }

                .types-icon-right {
                    display: none;
                }
            }
        }
    }

    &__experience {
        .exp-container {
            @media #{$min-width-mobile} {
                display: flex;
                justify-content: space-between;
                margin-top: snif-grid(6);

                .exp-item {
                    width: calc(100% / 3 - 40px);
                }
            }

            .exp-item {
                display: flex;
                padding-bottom: snif-grid(3);

                &:nth-last-child(1) {
                    padding-bottom: 0;
                }

                > svg {
                    width: 24px;
                    height: 24px;
                    color: $green;
                }

                .exp-item-right {
                    width: calc(100% - 40px);
                    margin-left: snif-grid(2);
                }
            }
        }
    }

    &__how-works {
        background-color: $bg-green-2;
        clear: both;

        .snif-work-list {
            .work-list-item {
                display: flex;
                margin-bottom: snif-grid(3);

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                > div {
                    width: calc(100% - 40px);
                    margin-left: snif-grid(2);
                }
            }

            @media #{$min-width-mobile} {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;

                .work-list-item {
                    width: calc(50% - 20px);
                    margin-bottom: 0;
                }
            }
        }
    }

    .sniff-rent-private {
        .sniff-stats {
            display: flex;

            > a {
                flex: 1;
                margin-right: snif-grid(2);
                margin-bottom: snif-grid(7);
                text-decoration: none !important;
                cursor: pointer;

                div {
                    color: $dark-gray;
                }

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }

            @media (max-width: $mobile-size) {
                justify-content: space-between;
                flex-wrap: wrap;

                > a {
                    flex: unset;
                    width: calc(50% - 16px);
                    margin-right: 0;
                    margin-bottom: snif-grid(4);
                }
            }
        }

        .rent-private-tbl {
            table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;

                @media (max-width: $mobile-size) {
                    position: relative;
                    left: -16px;
                    width: calc(100% + 32px);
                }

                thead th {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                    padding-bottom: snif-grid(2);

                    @media (max-width: $mobile-size) {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 600;
                        padding: snif-grid(2);

                        &:nth-last-child(1) {
                            width: 88px;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    td {
                        padding: snif-grid(3);
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 500;
                        border-right: 1px solid $fog-gray;
                        border-bottom: 1px solid $fog-gray;

                        &:nth-child(1) {
                            border-left: 1px solid $fog-gray;
                        }

                        @media (max-width: $mobile-size) {
                            padding: snif-grid(2);
                            font-size: 16px;
                            font-weight: 400;

                            &:nth-child(1) {
                                border-left-color: transparent;
                            }

                            &:nth-last-child(1) {
                                border-right-color: transparent;
                            }
                        }
                    }
                    tr:first-child td {
                        border-top: 1px solid $fog-gray;
                    }

                    @media (min-width: $mobile-size) {
                        tr:first-child td:first-child {
                            border-top-left-radius: 8px;
                        }
                        tr:first-child td:last-child {
                            border-top-right-radius: 8px;
                        }
                        tr:last-child td:first-child {
                            border-bottom-left-radius: 8px;
                        }
                        tr:last-child td:last-child {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
            }
        }
    }

    &__blog {
        a {
            color: unset !important;
            text-decoration: none !important;
        }

        .blog-recent-post-box {
            .blog-recent-post {
                display: block;
                margin-bottom: snif-grid(3);

                @media #{$max-width-mobile} {
                    > div {
                        display: flex;
                        align-items: center;
                    }
                }

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                img {
                    width: 72px;
                    height: 72px;
                    margin-right: snif-grid(2);
                    object-fit: cover;
                    object-position: center;
                    border-radius: 4px;
                }

                p {
                    width: calc(100% - 88px);
                    margin-bottom: 0;
                }
            }

            @media #{$min-width-mobile} {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;

                .blog-recent-post {
                    display: block;
                    width: calc(100% / 3 - 32px);
                    margin-bottom: 0 !important;

                    img {
                        width: 100%;
                        height: 264px;
                        margin-right: 0;
                        border-radius: 8px;
                    }

                    p {
                        width: 100%;
                        margin-top: snif-grid(2);
                    }
                }
            }
        }
    }

    &__faqs {
        background-color: $off-white;

        .faqs-listings-box {
            display: flex;
            flex-wrap: wrap;
            margin: 0 snif-grid(-3);

            .faqs-listings-item {
                width: 50%;
                padding: snif-grid(3);

                a {
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }
            }

            @media (max-width: $mobile-size) {
                display: block;
                margin: 0;

                .faqs-listings-item {
                    width: 100%;
                    padding: snif-grid(2) 0;
                }
            }
        }
    }

    &__hosting {
        .cover-img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            margin-bottom: snif-grid(2);
        }

        .hosting-benefit {
            display: flex;
            margin-bottom: snif-grid(3);

            svg {
                width: 24px;
                height: 24px;
                margin-right: snif-grid(2);
                color: $deep-gray;
            }

            > div {
                width: calc(100% - 40px);
            }
        }

        @media #{$min-width-mobile} {
            display: flex;

            .cover-img {
                width: 433px;
                height: 100%;
                margin-bottom: 0;
            }

            > div:nth-child(3) {
                margin-left: snif-grid(8);
            }
        }
    }

    &__explore {
        background-color: $bg-green-2;
        text-align: center;
    }

    &__reviews {
        .review-card-seo {
            margin-bottom: 0;

            @media #{$max-width-mobile} {
                height: 100%;
            }
        }

        .reviews-box {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 24px;

            > div {
                width: calc(100% / 4 - 18px);
                margin-bottom: 0;
            }

            @media #{$max-width-mobile} {
                gap: 12px;

                > div {
                    width: 100%;
                }
            }
        }
    }

    &__memberships-section {
        padding: snif-grid(2) snif-grid(2) snif-grid(3);
        background-color: $green-bg-subs;
        border: 1px solid $fog-gray;
        border-radius: 10px;
        cursor: pointer;

        .memberships-section-text {
            display: flex;
            align-items: center;
            margin-bottom: snif-grid(1);

            div {
                margin-left: snif-grid(2);
                font-weight: 600;
            }

            @media #{$min-width-mobile} {
                margin-bottom: 0;

                svg {
                    width: 32px;
                    height: 32px;
                }

                div {
                    margin-left: snif-grid(2);
                    font-weight: 700;
                }
            }
        }

        @media #{$min-width-mobile} {
            padding: snif-grid(4.5) snif-grid(4);
            position: relative;
            display: flex;
            align-items: center;

            .memberships-section-chv-right {
                position: absolute;
                top: 50%;
                right: 32px;
                transform: translateY(-50%);
            }
        }
    }

    &__app-download {
        margin: snif-grid(2) 0;
        background-color: $bg-green-2;

        .snif-section {
            @media #{$min-width-mobile} {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }

    .slide-mobile {
        padding-bottom: 0;
        margin-bottom: snif-grid(3);

        .splide__track {
            overflow: visible;
            overflow-x: hidden;

            @media #{$min-width-mobile} {
                padding: snif-grid(2) 0;
                overflow: hidden;
            }
        }
    }

    &__no-sub-container {
        display: flex;
        width: 100%;
        margin-bottom: snif-grid(2);
        padding: snif-grid(2);
        background: $green-bg-subs;
        border: 1px solid $green-100;
        border-radius: 8px;

        @media #{$min-width-mobile} {
            margin-bottom: snif-grid(1);
            padding: snif-grid(4);
        }

        &_btn {
            margin-top: snif-grid(2);

            @media #{$min-width-mobile} {
                position: absolute;
                top: 50%;
                right: 0;
                margin: 0;
                transform: translateY(-50%);
            }
        }

        > div {
            width: calc(100% - 36px);
        }

        svg {
            margin-right: snif-grid(1.5);
        }
    }

    &__upcoming-res {
        height: 100%;
        background: $white;
        border: 1px solid $fog-gray;
        border-radius: 12px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        cursor: pointer;

        @media #{$min-width-mobile} {
            display: flex;
            justify-content: space-between;
            padding: snif-grid(4);

            > div {
                width: 30%;
            }
        }

        &_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: snif-grid(2);
            border-bottom: 1px solid $fog-gray;

            @media #{$min-width-mobile} {
                justify-content: center;
                padding: 0;
                border-bottom: none;
            }
        }

        &_tag {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
            padding: 0 snif-grid(1.5);
            background-color: $success-500;
            border-radius: 100px;

            @media #{$min-width-mobile} {
                margin-right: snif-grid(4);
                padding: snif-grid(0.5) snif-grid(1.5);
            }
        }

        &_date {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 24px;

            svg {
                width: 20px;
                height: 20px;
                margin-right: snif-grid(1);
            }
        }

        &_thumb {
            object-fit: cover;
            object-position: center;
            margin-right: snif-grid(2);
            border-radius: 8px;
        }

        &_details {
            width: calc(100% - 72px);

            &_title {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: snif-grid(1.5);
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &_flags {
                display: flex;
                align-items: center;

                > div {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .review-block {
        background-color: $white;
        border: 1px solid $fog-gray;
        border-radius: 8px;

        .home__sniff-stars {
            .home__star {
                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }

        &_spot {
            display: flex;
            align-items: center;
            max-width: 100%;
            padding: snif-grid(2);
            margin-bottom: snif-grid(3);
            border-bottom: 1px solid $fog-gray;
            cursor: pointer;

            @media #{$min-width-mobile} {
                max-width: 380px;
                padding: 0;
                margin-bottom: 0;
                border-bottom: none;
            }

            img {
                margin-right: snif-grid(2);
                object-fit: cover;
                object-position: center;
            }

            > div {
                width: calc(100% - 72px);
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        @media #{$min-width-mobile} {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                width: 30%;
            }
        }
    }
}

.medium-gray-color {
    color: $medium-gray;

    a {
        color: $medium-gray;
    }
}

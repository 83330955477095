@import 'src/scss/functions';
@import 'src/scss/palette';

.render-app-download {
    padding: snif-grid(2) 0;

    &__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: $green-800;
        margin-bottom: snif-grid(4);
    }

    &__logos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-container {
            @media #{$max-width-mobile} {
                > picture img {
                    display: none;
                }
            }
        }

        > a {
            width: 50%;

            &:nth-child(1) {
                margin-right: snif-grid(2);
            }

            @media #{$max-width-mobile} {
                img,
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    @media #{$min-width-mobile} {
        display: flex;
        align-items: center;
        height: 416px;
        padding: 0;
        overflow: hidden;

        &__title {
            font-size: 36px;
            line-height: 48px;
            color: $dark-gray;
        }

        > div {
            width: 50%;

            img {
                object-fit: contain;
            }

            &:nth-child(2) {
                text-align: center;

                img {
                    position: relative;
                    top: 36px;
                    object-fit: contain;
                }
            }
        }

        &__logos {
            flex-direction: column;
            justify-content: center;

            &-container {
                display: flex;

                img {
                    margin-right: snif-grid(4);
                }
            }

            > a {
                width: 100%;

                &:nth-child(1) {
                    margin-right: 0;
                    margin-bottom: snif-grid(2);
                }
            }
        }
    }
}

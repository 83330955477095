@import 'src/scss/functions';
@import 'src/scss/palette';

.sniff-other-types {
    padding: snif-grid(10) 0 snif-grid(7);
    background-color: $bg-green-2;

    @media #{$max-width-mobile} {
        padding: snif-grid(4) 0;
    }

    .other-types-container {
        .other-types-item {
            display: block;
            margin-bottom: snif-grid(4);
            background-color: $white;
            border-radius: 8px;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                object-position: center;
                border-radius: 8px 8px 0 0;
            }

            .other-types-item-r {
                padding: snif-grid(3);
                border-radius: 0 0 8px 8px;

                .types-item-r-link {
                    display: flex;
                    align-items: center;
                    color: $green;
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-offset: 2px;

                    svg {
                        margin-left: snif-grid(1);
                    }
                }
            }
        }

        @media #{$min-width-mobile} {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 0 24px;

            .other-types-item {
                width: calc(100% / 3 - 16px);
                margin-bottom: snif-grid(3);

                &.is-sub-page {
                    width: calc(25% - 18px);
                }

                .other-types-item-r {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    height: calc(100% - 200px);
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    transition: all 300ms;
                }

                &:hover {
                    .other-types-item-r {
                        border-color: $green;
                    }
                }
            }
        }
    }
}

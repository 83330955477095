@import 'src/scss/palette';
@import 'src/scss/functions';

@import 'src/scss/functions';
@import 'src/scss/palette';

.slide-mobile {
    padding-bottom: snif-grid(3);

    > .splide--slide > .splide__track {
        margin: 0 -16px;
    }

    > .splide--slide > .splide__pagination {
        bottom: -32px;

        .splide__pagination__page {
            background-color: $fog-gray;

            &.is-active {
                background-color: $green;
                transform: unset;
            }
        }
    }

    .splide__arrow {
        padding: snif-grid(1);
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
        transition: all 300ms;
        opacity: 1;

        svg {
            width: 12px;
            height: 12px;
            fill: $deep-gray;
        }

        &:hover {
            background-color: $white;
        }

        &:disabled {
            opacity: 0;
        }

        &--prev {
            left: -16px;
        }

        &--next {
            right: -16px;
        }
    }
}

@import 'src/scss/palette';
@import 'src/scss/functions';

.hr {
    height: 0;
    margin: 0;
    background-color: $fog-gray;

    &_fat {
        height: snif-grid(0.5);
    }
}
